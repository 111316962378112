




































































































import { VeinShard } from "@/models";
import axios, { AxiosResponse } from "axios";
import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import PackageList from "./../components/PackageList.vue";
import vsDivider from "./../components/vsDivider.vue";

async function getState()
{
  let result = await axios.get("/@/state");
  return result.data;
}

@Component({
  components: {
    "package-list": PackageList,
    "vs-divider": vsDivider
  }
})
export default class home extends Vue
{
  popular_packages: any[] = [];
  packages_state: any[] = [];
  latest_packages: any[] = [];

  search_packages_result?: VeinShard[] = [];

  search_text: string = "";
  searchTimer: number = -1;
  searchLoading: boolean = false;
  searchAborter?: AbortController;
  statsHide: boolean = false;

  isSearchBegin: boolean = false;

  get result_search(): VeinShard[] {
    return this.search_packages_result ?? [];
  }
  set result_search(o: VeinShard[]){
    this.search_packages_result = o;
  }

  async created() 
  {
    /*
    this.fucking_auth0_handle = this.$vs.notification({
            icon: `<i class='bx bx-error' style='font-size: 2.2rem;'></i>`,
            color: `warn`,
            position: `top-left`,
            title: 'Important message!',
            text: `<p style='font-size: 1.4em;'>We had a conflict with <a href='http://auth0.com/'>Auth0</a> service, they deleted our user DB without any notice. <br/>
            If you have logged into your account and have not found permission to your packages, please write to us <a href='mailto:support@invocative.studio'>support@invocative.studio</a></p>`,
            duration: 15000
          });
    */
    this.$self_render.navbarEnable = true;
    let result = await getState();
    this.$loader.close();
    let maxItems = 5;
    this.popular_packages = result.popular_packages.slice(0, maxItems);
    this.latest_packages = result.latest_packages.slice(0, maxItems);
    this.packages_state = result.packages_state.slice(0, maxItems);
  }

  abortSearch() {
    clearTimeout(this.searchTimer);
    this.searchAborter?.abort();
    this.searchLoading = false;
  }

  @Watch('search_text')
  onSearchBegin(val: string, _: string) {
    if (!this.statsHide) this.statsHide = true;
    if (this.searchTimer !== -1) 
      this.abortSearch();
    if (val === "" || val.length < 2)
      return this.abortSearch();
    this.isSearchBegin = true;
    this.searchTimer = setTimeout(() => {
      this.searchLoading = true;
      this.searchAborter = new AbortController();
      this.$axios.$getCancelable(`@/search/index?q=${encodeURIComponent(val)}&take=100`, 
        this.searchAborter).then(this.populateResult);
    }, 750);
  }

  populateResult(o: AxiosResponse<any, any>){
    console.log(o);
    if (o.status != 200) return;
    this.result_search = (o.data as VeinShard[]);
    this.searchLoading = false;
  }
}
